export default defineI18nConfig(() => ({
  legacy: true,
  fallbackLocale: {
    'en-CA': ['en-US'],
    'fr-CA': ['fr'],
    'fr-BE': ['fr'],
    'nl-BE': ['nl', 'uk'],
  },
  compilation: {
    strictMessage: false,
  },
}));
